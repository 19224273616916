





























import { Component, Vue } from 'vue-property-decorator';

import StartButton from '@/components/StartButton.vue';
import {
  dispatchCancelYoutubeStat,
  dispatchGetYoutubeStatStatus,
  dispatchRunYoutubeStat,
} from '@/store/youtube-stat/actions';
import { readYoutubeStatFinished, readYoutubeStatState } from '@/store/youtube-stat/getters';
import { commitSetYoutubeStatFinished } from '@/store/youtube-stat/mutations';
import { commitAddNotification } from '@/store/main/mutations';
import { delay } from '@/utils';

const YT_STATUS_ACTIVE = 1;
const YT_STATUS_INERVAL = 30000; // 30 seconds

@Component({
  components: {
    StartButton,
  },
})
export default class YoutubeStat extends Vue {
  public timeOutPointer?: number = undefined;

  get ytState() {
    return readYoutubeStatState(this.$store);
  }

  get isFinished() {
    return readYoutubeStatFinished(this.$store);
  }

  public async handleGetStat() {
    await dispatchRunYoutubeStat(this.$store);
    this.checkYoutubeStatStatus();
  }

  public setFinished(value = true) {
    commitSetYoutubeStatFinished(this.$store, value);
  }

  public async checkYoutubeStatStatus(cancelled = false) {
    const data = await dispatchGetYoutubeStatStatus(this.$store);
    if (data.status === YT_STATUS_ACTIVE) {
      this.setFinished();
      clearInterval(this.timeOutPointer);
      this.timeOutPointer =  setTimeout(() => this.checkYoutubeStatStatus(), YT_STATUS_INERVAL);
    } else {
      this.ytState.status = null;
      if (this.isFinished) {
        const content = cancelled ? 'YouTube: задача отменена'
          : `YouTube: проверено ${data.done} каналов из ${data.total}`;
        commitAddNotification(this.$store, { content, color: 'info' });
        this.setFinished(false);
      }
    }
  }

  public async mounted() {
    this.checkYoutubeStatStatus();
  }

  public destroyed() {
    clearInterval(this.timeOutPointer);
  }

  public async handleCancelTask() {
    this.ytState.status = null;
    // используем delay, чтобы бэк успел обновить статус в redis
    const res = await Promise.all([dispatchCancelYoutubeStat(this.$store), delay(500)]);
    if (res) {
      clearInterval(this.timeOutPointer);
      this.checkYoutubeStatStatus(true);
    }
  }
}
